import { createAsyncThunk } from '@reduxjs/toolkit';
import ContactsService from '@/services/ContactsService';
import SenderService from '@/services/SenderService';

const handler = (name, asyncFunction) =>
  createAsyncThunk(`Sender/${name}`, async (arg) => {
    const data = await asyncFunction(arg);
    return data;
  });

export const getAfterSending = handler(
  'getAfterSending',
  ContactsService.getLeadsStatus
);

export const getProperties = handler(
  'getProperties',
  SenderService.getProperties
);

export const getRelated = handler(
  'getRelated',
  ContactsService.getRelatedContacts
);

export const getContactsAddressees = handler(
  'getContactsAddressees',
  ContactsService.getContacts
);

export const getContactsCC = handler(
  'getContactsCC',
  ContactsService.getContacts
);

export const getPropertyFiles = handler(
  'getPropertyFiles',
  SenderService.getPropertyFiles
);

export const getPropertiesFiles = handler(
  'getPropertiesFiles',
  SenderService.getPropertiesFilesById
);

export const getDevelopmentFiles = handler(
  'getDevelopmentFiles',
  SenderService.getDevelopmentFiles
);

export const getPropertyAndDevelopmentFiles = handler(
  'getPropertyAndDevelopmentFiles',
  SenderService.getPropertyAndDevelopmentFiles
);

export const getContactReports = handler(
  'getContactReports',
  SenderService.getContactReports
);

export const getContactsWhatsapp = handler(
  'getContactsWhatsapp',
  ContactsService.getContacts
);

export const getPreSelectedContacts = handler(
  'getPreSelectedContacts',
  ContactsService.getContact
);

export const getPreSelectedPropertiesById = handler(
  'getPreSelectedPropertiesById',
  SenderService.getPropertiesById
);

export const getPreSelectedPropertiesByHash = handler(
  'getPreSelectedPropertiesByHash',
  SenderService.getPropertiesByHash
);

export const getPreSelectedDevelopments = handler(
  'getPreSelectedDevelopments',
  SenderService.getDevelopmentsById
);

export const getStarred = handler('getStarred', ContactsService.getStarred);

export const getStarredForEmail = handler(
  'getStarredForEmail',
  ContactsService.getStarred
);

export const getStarredForWhatsapp = handler(
  'getStarredForWhatsapp',
  ContactsService.getStarred
);

export const sendEmail = handler('sendEmail', SenderService.sendEmail);

export const validateWhatsapp = handler(
  'validateWhatsapp',
  SenderService.validateWhatsapp
);

export const validateEmail = handler(
  'validateEmail',
  ContactsService.checkIfExistsByEmail
);

export const validateCellphone = handler(
  'validateCellphone',
  ContactsService.checkIfExistsByCellphone
);

export const getContactReactions = handler(
  'getContactReactions',
  ContactsService.getContactReaction
);
